import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Grid } from "@material-ui/core";

// custom components
import Header from "../../components/Header";
import Banner from "../../components/Banner";
import HomeGallery from "../../components/HomeGallery";
import Footer from "../../components/Footer";
import Seo from "../../components/Seo";
import CallAction from "../../components/CallAction";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "90%",
    margin: "0 auto",
    overflowX: "hidden",
  },
  section: {
    padding: 30,
  },
  title: {
    textAlign: "center",
    marginTop: 30,
    marginBottom: 30,
  },
  body: {
    margin: "25px 0px",
  },
  description: {
    marginTop: 30,
  },
  secCol: {
    display: "flex",
  },
  highlight: {
    padding: 10,
    background: "#eacc4e",
    marginBottom: 20,
    color: "#fff",
    fontSize: 25,
    marginRight: 10,
  },
  phone: {
    color: "#d2b641",
    fontWeight: "bold",
  },
  img: {
    width: 248,
    margin: "0 auto",
  },
}));

// markup
const IndexPage = () => {
  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query {
      image_1: file(relativePath: { eq: "map_image.png" }) {
        childImageSharp {
          fluid(maxHeight: 148, maxWidth: 248) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <div className={classes.root}>
      <Seo
        title='Online leak sealing & maintenance service'
        description='We specialize in leak remediation services. Our service team with many years of experience in handling at large factories in Vietnam, technicians are trained to handle fast nationwide, ensuring fast response times.'
        lang='en'
      />

      {/** Header */}
      <Header language='en' currentPath='/' />

      {/** Main */}
      <main className={classes.main}>
        <Banner />

        {/** Section 1 */}
        <div name='service' className={classes.section} style={{ backgroundColor: "#f5efd3" }}>
          <Typography variant='h4' className={classes.title}>
            Leak repair service
          </Typography>
          <Typography variant='body1' align='center' className={classes.body}>
            Industrial leaks are a primary concern to our customers in terms of
            safety, productivity loss and the environment. Some critical leaks
            can also lead to shutdowns if no technical alternative is provided
            quickly and safely.Our service team with many years of experience in
            handling at large factories in Vietnam, technicians are trained to
            handle fast nationwide, ensuring fast response times.
          </Typography>
          <Grid container spacing={4}>
            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#1</span>
              <Typography variant='h5'>Flange joints</Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#2</span>
              <Typography variant='h5'>
                On the tube & tube components
              </Typography>
            </Grid>

            <Grid item md={4} xs={12} className={classes.secCol}>
              <span className={classes.highlight}>#3</span>
              <Typography variant='h5'>On valve glands</Typography>
            </Grid>
          </Grid>
        </div>

        {/** Section 2 */}
        <div className={classes.section}>
          <Typography variant='h4' className={classes.title}>
            Online Leak Sealing
          </Typography>

          <Typography variant='h6' className={classes.step}>
            I. Process
          </Typography>

          <Typography variant='body1'>
            Online leak sealing today is the leak-sealing solution of choice as
            it saves energy, prevents an expensive and unwanted shutdown and can
            address a wide variety of leaks. Our leak-sealing compounds can
            address a wide variety of steam, chemical, hydrocarbon and gas leaks
            at temperatures up to 500°C and pressures up to 350 bars. Our
            compounds have been tested in thousands of applications over several
            years and you can be confident of their performance. Our compounds
            are design, test and manufacture comply with ISO 9001 and ISO 14001
            standard, thus assuring quality to the utmost.
          </Typography>

          <Typography variant='body1' className={classes.bold}>
            Our service team is trained and finished projects in many industries
            filed to ensure quick response time and prompt attention.
          </Typography>

          <Typography variant='h6' className={classes.step}>
            II. Application
          </Typography>

          <Typography variant='body1' className={classes.bold}>
            Standard leaks that can be sealed using our online process:
          </Typography>
          <ul>
            <li>Flange joints</li>
            <li>Pipes and piping components</li>
            <li>Valve glands</li>
          </ul>
          <Typography variant='body1' className={classes.bold}>
            We have also developed effective solutions for several unique
            leakage problems in equipment such as:
          </Typography>
          <ul>
            <li>Transformers</li>
            <li>Ball valves</li>
            <li>Pressure seal valve bonnets</li>
            <li>Tank roof</li>
            <li>Fin fan coolers</li>
          </ul>
          <Typography variant='body1'>
            We further have the ability to provide custom-engineered solutions
            based on clients' problem.
          </Typography>
        </div>

        {/** Section 3 */}
        <div name='contact' className={classes.section}>
          <Typography variant='h3' className={classes.title}>
            Contact Us
          </Typography>

          <Grid container spacing={0}>
            <Grid item md={4} xs={12}>
              <Img
                className={classes.img}
                fluid={data.image_1.childImageSharp.fluid}
              />
              <Typography variant='h5'>
                OS TECHNICAL SERVICES CO., LTD
              </Typography>
              <Typography variant='body1'>
                Address: 38E Tran Cao Van Street - Ward 06, District 3, HCM City
                - Vietnam
              </Typography>
              <Typography variant='body1'>Mobile: 0937415072</Typography>
              <Typography variant='body1'>MST: 0315535383</Typography>
              <Typography variant='body1'>
                Web site: https://osts.com.vn
              </Typography>
              <Typography variant='body1'>
                Email: service@khacphucrori.com
              </Typography>
            </Grid>

            <Grid item md={8} xs={12}>
              <iframe
                title='map'
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.3632963137743!2d106.69401131526028!3d10.783462262011364!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752f3684cd6e21%3A0x7d9b8fd1859b3357!2zMzhlIFRy4bqnbiBDYW8gVsOibiwgUGjGsOG7nW5nIDYsIFF14bqtbiAzLCBI4buTIENow60gTWluaCwgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1551974547033'
                width='100%'
                height='450'
                frameborder='0'
                style={{ border: 0 }}
              />
            </Grid>
          </Grid>
        </div>

        {/** Gallery */}
        <HomeGallery language="en" />
      </main>

      {/** Call action */}
      <CallAction />

      {/** Footer */}
      <Footer />
    </div>
  );
};

export default IndexPage;
